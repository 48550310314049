<template>
    <BaseSection
        class="section--full-width-image"
        width="bleed"
    >
        <FullWidthImage :images="content.images" />
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
    },
};
</script>
