<template>
    <BaseSection
        v-if="image"
        width="content"
        background="none"
        :class="largeHeader ? 'section--large-header' : ''"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <template
            v-if="description"
            v-slot:description
        >
            <div v-html="description" /><!-- eslint-disable-line -->
        </template>
        <ImageAsset
            :image="image"
            :caption="content.caption || undefined"
            :image-alt="altText"
        />
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },

    computed: {
        header() {
            return this?.content?.header ?? '';
        },
        description() {
            return this?.content?.richTextSimple ?? '';
        },
        largeHeader() {
            return this?.content?.largeHeader ?? true;
        },
        altText() {
            return this?.content?.altText ?? '';
        },
        image() {
            return this?.content?.image?.[0] ?? null;
        }
    }
};
</script>
