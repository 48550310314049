<template>
    <Transition
        v-cloak
        name="cookiebar"
        appear
    >
        <div
            v-if="showCookiebar"
            class="cookiebar"
            role="alert"
            data-nosnippet
        >
            <div class="cookiebar__body">
                <slot
                    :accept-all-cookies="acceptAllCookies"
                    :dismiss-cookiebar="dismissCookiebar"
                    :is-saving="isSaving"
                />
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { useCookiebar } from './useCookiebar';

const props = defineProps({
    isVisible: {
        type: Boolean,
        default: null
    }
});
const { isVisible } = toRefs(props);

const { acceptAllCookies, dismissCookiebar, isCookiebarDismissed, isSaving} = useCookiebar();

const showCookiebar = computed(() => {
    if (isVisible.value === false || isVisible.value === true) {
        return isVisible;
    }

    return !isCookiebarDismissed.value;
});
</script>

<style lang="less" src="./CookieBar.less" scoped />
