import { trim } from 'lodash-es';

import { EXTERNAL, INTERNAL } from '~/components/NavLink/NavLink';
import { HOME_URI } from '~/constants/page-uri';

export default {
    methods: {
        mapType(type) {
            switch (type) {
            case 'linkSingle_internalLink_BlockType':
            case 'linkMultiple_internalLink_BlockType':
            case 'linkSingleNoTitle_internalLink_BlockType':
                return INTERNAL;
            case 'linkSingle_externalLink_BlockType':
            case 'linkMultiple_externalLink_BlockType':
            case 'linkSingleNoTitle_externalLink_BlockType':
                return EXTERNAL;
            }
        },
        mapURI(link) {
            switch (this.mapType(link.__typename)) {
            case INTERNAL:
            {
                const uri = link?.page?.[0]?.uri ?? '';
                const url = this.fixHomeUri(uri);
                const tUrl = trim(url, '/');

                return tUrl ? `/${tUrl}/` : '/';
            }
            case EXTERNAL:
                if (typeof link === 'string') {
                    if (link[0] === '/') {
                        // If route parameter is a path, create route object with path.
                        return this.localePath(link);
                    }
                }

                return link.linkUrl;
            }
        },
        getLink(entry) {
            const uri = entry?.uri ?? '';
            const url = this.fixHomeUri(uri);
            const tUrl = trim(url, '/');

            return tUrl ? `/${tUrl}/` : '/';
        },
        fixHomeUri(uri) {
            if (uri === HOME_URI) {
                return '';
            }

            return uri;
        }
    }
};
