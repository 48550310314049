import { villusBugsnag } from '@digitalnatives/villus-plugins';
import { defaultPlugins, definePlugin } from 'villus';
import { print } from 'graphql/language/printer';

export default defineGraphqlConfig(() => {
    const config = useRuntimeConfig();
    const { query } = useRoute();

    /**
     * Handles errors from the server.
     */
    const errorHandlerPlugin = definePlugin(({ operation, opContext, afterQuery }) => {
        afterQuery(({ error }, { response }) => {
            if (error) {
                // Catch empty or 500 responses from the server, GraphQL errors are processed by dn-graphql-client or other plugins
                if (!Object.prototype.hasOwnProperty.call(error, 'networkError')) {
                    return;
                }

                const operationContext = {
                    endpoint: opContext.url,
                    headers: opContext.headers,
                    query: typeof operation.query === 'string' ? operation.query : print(operation.query),
                    type: operation.type,
                    variables: operation.variables,
                };

                if (import.meta.env.DEV) {
                    console.error(error.message, { error, operationContext, response });
                }

                throw new Error(error.message);
            }
        });
    });

    /**
     * Sets the craft token header for all requests if defined in the URL
     */
    const craftTokenPlugin = definePlugin(({ opContext }) => {
        if (query?.token) {
            // This is the actual header which Craft needs
            if (query?.['x-craft-live-preview'] && query?.token) {
                opContext.headers['X-Craft-Token'] = query?.token?.toString();
            }
        }
    });


    return {
        villus: {
            url: config.public.apiEndpoint,
            use: [villusBugsnag(), errorHandlerPlugin, craftTokenPlugin, ...defaultPlugins()],
        }
    };
});
