<template>
    <BaseSection
        class="section--announcement-card"
        :class="[header ? 'section--header' : '']"
        data-width="wide-content"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <template #default>
            <div class="section--announcement-card__items">
                <announcement-card
                    v-for="(announcementCard, index) in announcementCards"
                    :key="index"
                    :content="announcementCard"
                />
            </div>
        </template>
    </BaseSection>
</template>

<script>
import AnnouncementCard from '~/components/AnnouncmentCard/AnnouncementCard.vue';

export default {
    components: { AnnouncementCard },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        header() {
            return this?.content?.header ?? '';
        },
        announcementCards() {
            return this?.content?.announcementCards ?? [];
        }
    }
};
</script>

<style lang="less">
.section--announcement-card__items {
    display: grid;
    gap: 1rem;

    @media @q-md-min {
        gap: 2.5rem;
    }
}
</style>
