<template>
    <BaseSection data-width="content">
        <TheQuote
            :text="text"
            :author="author"
            :image="image"
        />
    </BaseSection>
</template>

<script>
import { get } from 'lodash-es';

export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        text() {
            return get(this, 'content.quoteText', '');
        },
        author() {
            return get(this, 'content.quoteAuthor', '');
        },
        image() {
            return get(this, 'content.image.0', {});
        }
    }
};
</script>
