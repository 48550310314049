export const useFavicon = () => {
    useHead({
        link: [
            {
                rel: 'shortcut icon',
                href: '/favicon.ico'
            },
            {
                rel: 'apple-touch-icon',
                type: 'image/png',
                sizes: '180x180',
                href: '/apple-touch-icon-180x180.png'
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '64x64',
                href: '/pwa-64x64.png'
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '192x192',
                href: '/pwa-192x192.png'
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '512x512',
                href: '/pwa-512x512.png'
            },
        ],
        meta: [
            {
                name: 'msapplication-TileColor',
                content: '#ffffff'
            },
            {
                name: 'theme-color',
                content: '#ffffff'
            }
        ]
    });
};
