<template>
    <BaseSection
        class="section--icon-list"
        :class="[{ 'section--header': header }, themeClass]"
        width="page"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <div class="section--icon-list__wrapper">
            <IconListItem
                v-for="(item, i) in listItems"
                :key="`icon-list-item-${i}`"
                :is-expandable="!!item.expandableContent"
            >
                <template v-slot:icon>
                    <BaseIcon :icon="item.icon" />
                </template>
                <template v-slot:header>
                    {{ item.title }}
                </template>

                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="article"
                    v-html="item.expandableContent"
                />
                <!-- eslint-enable vue/no-v-html -->
            </IconListItem>
        </div>
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
    },
    computed: {
        header() {
            return this?.content?.header ?? '';
        },
        sectionTheme() {
            return this?.content?.sectionTheme ?? '';
        },
        themeClass() {
            switch (this.sectionTheme) {
            case 'blue':
                return 'theme-section--blue';
            case 'brown':
                return 'theme-section--brown';
            case 'red':
                return 'theme-section--red';
            case 'green':
                return 'theme-section--green';
            default:
                return null;
            }
        },
        listItems() {
            return this?.content?.listItems ?? [];
        },
    },
};
</script>

<style lang="less">
.section--icon-list__wrapper {
    padding: 2.5rem 1.25rem;

    @media @q-md-min {
        padding: 2.375rem 6.4375rem;
    }
}
</style>
