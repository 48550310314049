import { default as _91_46_46_46slug_93JdRbGEhlujMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/[...slug].vue?macro=true";
import { default as _91slug_93Sqszwrho2HMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/blog/[category]/[slug].vue?macro=true";
import { default as indexRFKpyq901MMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/blog/[category]/index.vue?macro=true";
import { default as indexN4GMwwW1d5Meta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/blog/index.vue?macro=true";
import { default as connectD9JzvL9xBRMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/connect.vue?macro=true";
import { default as _91_46_46_46slug_93N4RXMH36QeMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/[...slug].vue?macro=true";
import { default as index5IYpQfdocWMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/index.vue?macro=true";
import { default as indexeI7aNBzYhAMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/nominate/index.vue?macro=true";
import { default as thank_45youiHWjfPvZO0Meta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/nominate/thank-you.vue?macro=true";
import { default as you_45are_45nominatedvr8Nmdf4PvMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/you-are-nominated.vue?macro=true";
import { default as indexdnIlwjztSVMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/index.vue?macro=true";
import { default as _91category_93cSWutwgTHTMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/meet-us/[category].vue?macro=true";
import { default as indexicgxXtcMBuMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/meet-us/index.vue?macro=true";
import { default as _91_46_46_46slug_936gFgWJUhZbMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/partners/[...slug].vue?macro=true";
import { default as search4wY8UZBbPXMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/pages/search.vue?macro=true";
import { default as component_45stub3gfhkm93HRMeta } from "/builds/hotelschool-the-hague/hotelschool-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub3gfhkm93HR } from "/builds/hotelschool-the-hague/hotelschool-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/[...slug].vue")
  },
  {
    name: "blog-category-slug___en",
    path: "/blog/:category()/:slug()",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/blog/[category]/[slug].vue")
  },
  {
    name: "blog-category___en",
    path: "/blog/:category()",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/blog/[category]/index.vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/blog/index.vue")
  },
  {
    name: "connect___en",
    path: "/connect",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/connect.vue")
  },
  {
    name: "hicons-slug___en",
    path: "/hicons/:slug(.*)*",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/[...slug].vue")
  },
  {
    name: "hicons___en",
    path: "/hicons",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/index.vue")
  },
  {
    name: "hicons-nominate___en",
    path: "/hicons/nominate",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/nominate/index.vue")
  },
  {
    name: "hicons-nominate-thank-you___en",
    path: "/hicons/nominate/thank-you",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/nominate/thank-you.vue")
  },
  {
    name: "hicons-you-are-nominated___en",
    path: "/hicons/you-are-nominated",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/hicons/you-are-nominated.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/index.vue")
  },
  {
    name: "meet-us-category___en",
    path: "/meet-us/:category()",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/meet-us/[category].vue")
  },
  {
    name: "meet-us___en",
    path: "/meet-us",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/meet-us/index.vue")
  },
  {
    name: "partners-slug___en",
    path: "/partners/:slug(.*)*",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/partners/[...slug].vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/builds/hotelschool-the-hague/hotelschool-frontend/pages/search.vue")
  },
  {
    name: component_45stub3gfhkm93HRMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub3gfhkm93HR
  },
  {
    name: component_45stub3gfhkm93HRMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stub3gfhkm93HR
  },
  {
    name: component_45stub3gfhkm93HRMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stub3gfhkm93HR
  }
]