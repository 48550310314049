<template>
    <nav
        class="the-navigation"
        :data-scrolled="scrolled ? true : null"
    >
        <a
            href="#bypass-navigation"
            class="the-navigation__skiplink"
        >
            {{ t('navigation.skip-navigation') }}
        </a>
        <a
            href="#footer"
            class="the-navigation__skiplink"
        >
            {{ t('navigation.skip-to-footer') }}
        </a>
        <div class="the-navigation__bar">
            <NuxtLink
                to="/"
                class="the-navigation__logo"
            >
                <BaseIcon
                    icon="hth-mobile"
                    class="the-navigation__logo__mobile"
                />
                <BaseIcon
                    icon="hth-desktop"
                    class="the-navigation__logo__desktop"
                />

                <div class="sr-only">Home</div>
            </NuxtLink>

            <button
                class="the-navigation__burger button--clean"
                @click="expandMenu"
            >
                <BaseIcon icon="menu" />
                <span class="sr-only">{{ t('navigation.sr-open-menu') }}</span>
            </button>

            <template v-if="!mews">
                <button
                    v-if="returnTo && referrer"
                    class="the-navigation__close button--icon"
                    @click="goBack"
                >
                    <BaseIcon icon="close" />
                    <span class="sr-only">{{ t('navigation.previous-page') }}</span>
                </button>

                <NuxtLink
                    v-if="returnTo && !referrer"
                    class="the-navigation__close button--icon"
                    :to="returnTo"
                >
                    <BaseIcon icon="close" />
                    <div class="sr-only">{{ t('navigation.previous-page') }}</div>
                </NuxtLink>
            </template>

            <button
                v-if="mews"
                class="the-navigation__cta button--primary button--inline distributor-open"
            >
                Book now
            </button>

            <NavLink
                v-if="!returnTo && ctaLink"
                :type="mapType(ctaLink.__typename)"
                :url="mapURI(ctaLink)"
                :blank="ctaLink.openInNewWindow"
                class="the-navigation__cta button--primary button--inline"
                data-ga-category="navigation-cta-click"
                :data-ga-action="mapURI(ctaLink)"
                :data-ga-label="$route.path"
                data-ga4-event="navigation_cta_click"
            >
                {{ ctaTitle }}
            </NavLink>
        </div>

        <button
            v-if="expanded"
            class="the-navigation__clickaway"
            aria-label="close menu"
            @click="closeMenu"
        />

        <div
            ref="inner"
            class="the-navigation__inner"
            :aria-hidden="!expanded ? true : null"
        >
            <FocusLoop :is-visible="expanded">
                <div class="the-navigation__bar">
                    <NuxtLink
                        to="/"
                        class="the-navigation__logo"
                        @click="closeMenu"
                    >
                        <BaseIcon icon="hth-mobile" />
                        <div class="sr-only">Home</div>
                    </NuxtLink>

                    <NuxtLink
                        to="/search/"
                        class="the-navigation__search"
                        @click="closeMenu"
                    >
                        <BaseIcon icon="search" /><span class="sr-only">Search</span>
                    </NuxtLink>

                    <button
                        class="the-navigation__close button--icon"
                        @click="closeMenu"
                    >
                        <BaseIcon icon="close" /><span class="sr-only">{{ t('navigation.sr-close-menu') }}</span>
                    </button>
                </div>

                <div class="the-navigation__inner-wrap">
                    <ul class="the-navigation__main-links">
                        <li
                            v-for="(link, index) in mainNavigation"
                            :key="index"
                        >
                            <NavSublinked
                                :link="link"
                                class="the-navigation__link"
                                ga4-event-name="menu_click"
                                @close-menu="closeMenu"
                            />
                        </li>
                    </ul>

                    <ul class="the-navigation__secondary-links">
                        <li
                            v-for="(link, index) in secondaryLinkList"
                            :key="index"
                        >
                            <NavLink
                                :type="mapType(link.__typename)"
                                :url="mapURI(link)"
                                :blank="link.openInNewWindow"
                                class="the-navigation__link"
                                data-ga-category="navigation-secondary"
                                data-ga-action="click"
                                :data-ga-label="mapURI(link)"
                                data-ga4-event="menu_click"
                                @click="closeMenu"
                            >
                                {{ link.header }}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </FocusLoop>
        </div>

        <div
            id="bypass-navigation"
            tabindex="-1"
            style="opacity: 0; pointer-events: none;"
        />
    </nav>
</template>

<script setup>
import { FocusLoop } from '@vue-a11y/focus-loop';
import { throttle } from 'lodash-es';

import getMainNavigation from '~/graphql/queries/getMainNavigation.graphql';

const props = defineProps({
    callToAction: {
        type: Object,
        required: false,
        default() {
            return {};
        }
    },
    fixed: {
        type: Boolean,
        default: false
    },
    returnTo: {
        type: String,
        default: ''
    },
    mews: {
        type: Object,
        default: null
    }
});

const { callToAction, fixed } = toRefs(props);

const { t } = useI18n();
const router = useRouter();
const { $i18n } = useNuxtApp();
const siteHandle = $i18n?.localeProperties?.value?.craftSiteHandle;
const variables = computed(() => {
    return {
        siteHandle
    };
});

const { data } = await useAsyncQuery({
    query: getMainNavigation,
    variables
});

const mainNavigation = computed(() => data.value?.globalSet?.mainNavigation);
const secondaryNavigation = computed(() => data.value?.globalSet?.secondaryNavigation);

const secondaryLinkList = computed(() => secondaryNavigation.value?.[0]?.linkMultiple ?? []);
const ctaTitle = computed(() => callToAction.value?.title ?? '');
const ctaLink = computed(() => callToAction.value?.linkSingleNoTitle?.[0] ?? '');


const url = useRequestURL();
const referrer = computed(() => {
    if (!windowIsDefined()) {
        return null;
    }

    if (!document.referrer.includes(url.hostname) || document.referrer === url.href) {
        return null;
    }

    if (document.referrer === '') {
        return null;
    }

    return document.referrer;
});

const expanded = ref(false);
const scrolled = ref(false);

const handleScroll = () => {
    scrolled.value = fixed.value || window.scrollY > 0;
};

/**
 * Lock scroll and prevent page from jumping when scrollbar is added
 * @param lock
 */
const lockBackgroundScroll = (lock = true) => {
    document.body.style.overflow = lock ? 'hidden' : 'auto';
};

const expandMenu =() =>  {
    expanded.value = true;
    window.addEventListener('keyup', closeOnEscape);
    lockBackgroundScroll();
};

const closeMenu = () => {
    expanded.value = false;
    window.removeEventListener('keyup', closeOnEscape);
    lockBackgroundScroll(false);
};

const closeOnEscape = (event) => {
    if (event.key === 'Escape') {
        closeMenu();
    }
};

const goBack = () => {
    router.back();
};

onMounted(() => {
    closeMenu();
    handleScroll();
    window.addEventListener('scroll', throttle(handleScroll, 300));
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
};
</script>

<style src="./TheNavigation.less" lang="less" scoped />
