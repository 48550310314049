<template>
    <NavLink
        v-if="!children.length"
        :type="mapType(linkSingle.__typename)"
        :url="mapURI(linkSingle)"
        :blank="linkSingle.openInNewWindow"
        data-ga-category-specific="navigation-primary"
        data-ga-action="click-mainlink"
        :data-ga-label="mapURI(linkSingle)"
        :data-ga4-event="ga4EventName"
        @click="$emit('close-menu')"
    >
        {{ linkSingle.header }}
    </NavLink>
    <div v-else>
        <TheAccordion>
            <template v-slot:header>{{ header }}</template>
            <ul class="the-navigation__secondary-links">
                <li
                    v-for="(child, index) in children"
                    :key="index"
                >
                    <NavLink
                        :type="mapType(child.__typename)"
                        :url="mapURI(child)"
                        :blank="child.openInNewWindow"
                        class="the-navigation__link"
                        data-ga-category-specific="navigation-primary"
                        data-ga-action="click-sublink"
                        :data-ga-label="mapURI(child)"
                        :data-ga4-event="ga4EventName"
                    >
                        {{ child.header }}
                    </NavLink>
                </li>
            </ul>
        </TheAccordion>
    </div>
</template>

<script>
import { get } from 'lodash-es';

import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],

    props: {
        ga4EventName: {
            type: String,
            required: true
        },
        link: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },

    emits: ['close-menu'],

    computed: {
        linkSingle() {
            return get(this, 'link.linkSingle.0', []);
        },
        header() {
            return get(this, 'link.header', []);
        },
        children() {
            return get(this, 'link.children.0.linkMultiple', []);
        }
    }
};
</script>
