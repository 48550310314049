<template>
    <div 
        :id="anchorId" 
        class="the-accordion"
    >
        <button
            class="the-accordion__header"
            :aria-controls="`${componentId}-content`"
            :aria-expanded="expanded"
            @click="toggleContent"
        >
            <slot name="header" />
            <BaseIcon :icon="expanded ? iconClose : iconOpen" />
        </button>
        <div
            v-show="expanded"
            :id="`${componentId}-content`"
            class="the-accordion__content"
            :aria-labelledby="`${componentId}-button`"
        >
            <slot />
        </div>
    </div>
</template>

<script setup>
const route = useRouter();

let uuid = 0;

const props = defineProps({
    iconOpen: {
        type: String,
        default: 'plus'
    },
    iconClose: {
        type: String,
        default: 'minus'
    },
    anchorId: {
        type: String,
        default: null
    }
});

const expanded = ref(false);
const componentId = ref(null);

onMounted(() => {
    componentId.value = `accordion_${uuid}`; // Make sure each accordion has it's own ID to prevent duplicate IDs
    uuid += 1; // Increment accordion counter
});

const toggleContent = () => {
    expanded.value = !expanded.value;
};

watch(() => route.currentRoute.value.hash, (newValue) => {
    if (newValue === `#${props.anchorId}`) {
        expanded.value = true;
    }
});
</script>

<style lang="less" src="./TheAccordion.less"></style>
