<template>
    <BaseSection
        v-if="link"
        width="content"
        class="section--button"
    >
        <NavLink
            :type="mapType(link.__typename)"
            :url="mapURI(link)"
            :blank="link.openInNewWindow"
            class="button button--primary"
            data-ga-category-specific="button-click"
            :data-ga-action="mapURI(link)"
            :data-ga-label="$route.path"
        >
            {{ link.header }}
        </NavLink>
    </BaseSection>
</template>

<script>
import { get } from 'lodash-es';
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],

    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },

    computed: {
        link() {
            return get(this, 'content.linkSingle.0', null);
        }
    }
};
</script>
