<template>
    <NuxtLink
        v-if="type === INTERNAL"
        :to="url"
    >
        <slot />
    </NuxtLink>

    <a
        v-else-if="type === EXTERNAL"
        :href="url"
        rel="noopener noreferrer"
        :target="blank ? '_blank' : null"
    >
        <slot />
    </a>
</template>

<script>
export const INTERNAL = 'internal';
export const EXTERNAL = 'external';

export default {
    props: {
        type: {
            type: String,
            validator: value => [INTERNAL, EXTERNAL].includes(value),
            default: INTERNAL
        },
        url: {
            type: [String, Object],
            required: true
        },
        blank: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            INTERNAL,
            EXTERNAL
        };
    }
};
</script>
