const windowIsDefined = () => typeof window !== 'undefined';

export default defineNuxtRouteMiddleware((_, { name, path }) => {
    if (windowIsDefined()) {
        Object.defineProperty(
            document,
            'referrer',
            {
                configurable: true,
                get: () => {
                    return name ? `${window.location.origin}${path}` : '';
                },
            }
        );
    }
});
