import { defineNuxtPlugin } from '#imports';

function inViewUpdate(entries) {
    for (const entry of entries) {
        entry.target.classList.toggle('inview--viewing', entry.isIntersecting);

        if (entry.isIntersecting) {
            entry.target.dispatchEvent(new Event('enter'));
            entry.target.classList.add('inview--viewed');
        } else {
            entry.target.dispatchEvent(new Event('leave'));
        }
    }
}

const windowIsDefined = () => typeof window !== 'undefined';

export default defineNuxtPlugin((nuxtApp) => {
    if (!windowIsDefined()) {
        nuxtApp.vueApp.directive('inview', {});
        return;
    }

    const inViewOptions = {
        threshold: [0.25]
    };

    const inViewObserver = new IntersectionObserver(inViewUpdate, inViewOptions);

    nuxtApp.vueApp.directive('inview', {
        mounted(el) {
            el.classList.add('inview--observing');
            inViewObserver.observe(el);
        },

        unmounted(el) {
            if (el instanceof Element) {
                el.classList?.remove('inview--observing');
                inViewObserver.unobserve(el);
            }
        }
    });
});
