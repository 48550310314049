<template>
    <div>
        <TheNavigation return-to="/" />
        <BaseSection
            width="content"
            :background="'background--beige'"
            class="error"
        >
            <h1
                v-if="notFound"
                class="error__title"
            >
                {{ t('error.not-found.title') }}
            </h1>
            <h1
                v-else
                class="error__title"
            >
                {{ t('error.server.title') }}
            </h1>
            <p
                v-if="notFound"
                class="error__description"
            >
                {{ t('error.not-found.description') }}
            </p>
            <p
                v-else
                class="error__description"
            >
                {{ t('error.server.description') }}
            </p>
            <nuxt-link
                :to="`${localePath({ name: 'index' })}/`"
                class="button--primary"
            >
                {{ t('error.server.homelink') }}
            </nuxt-link>
        </BaseSection>
    </div>
</template>

<script setup>
const { t } = useI18n();

defineProps({
    notFound: {
        type: Boolean,
        default: false,
        required: false
    }
});

const config = useRuntimeConfig();

useHead({
    title: `${config.public.defaults.siteTitle} | ${t('error.not-found.title')}`
});
</script>

<style lang="less" src="./TheError.less"></style>
