<template>
    <BaseSection
        class="section--facts-and-figures"
        :class="header ? 'section--header' : ''"
        data-width="wide-content"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <template #default>
            <FactsAndFigures
                :items="factsAndFigures"
                :show-all="showAll"
            />
        </template>
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        header() {
            return this?.content?.header ?? '';
        },
        factsAndFigures() {
            return this?.content?.factsAndFigures ?? [];
        },
        showAll() {
            return this?.content?.showAll ?? false;
        }
    }
};
</script>
