<template>
    <BaseSection
        v-if="content && content.richText"
        width="content"
        :background="'none'"
    >
        <RichText :text="content.richText || undefined" />
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    }
};
</script>
