<template>
    <footer
        id="footer"
        tabindex="-1"
        class="the-footer"
    >
        <div class="the-footer__inner">
            <ul class="the-navigation__main-links">
                <li
                    v-for="(link, index) in mainNavigation"
                    :key="index"
                >
                    <NavSublinked
                        :link="link"
                        class="the-navigation__link"
                        ga4-event-name="footer_click"
                    />
                </li>
            </ul>

            <ul class="the-navigation__secondary-links">
                <li
                    v-for="(link, index) in secondaryLinkList"
                    :key="index"
                >
                    <NavLink
                        :type="mapType(link.__typename)"
                        :url="mapURI(link)"
                        :blank="link.openInNewWindow"
                        class="the-navigation__link"
                        data-ga-category="footer-secondary"
                        data-ga-action="click"
                        :data-ga-label="mapURI(link)"
                        data-ga4-event="footer_click"
                    >
                        {{ link.header }}
                    </NavLink>
                </li>
            </ul>

            <div class="the-navigation__bottom-bar">
                <SocialLinks ga-category="footer-social" />

                <ul class="the-navigation__privacy-links">
                    <li
                        v-for="(link, index) in privacyLinkList"
                        :key="index"
                    >
                        <NavLink
                            :type="mapType(link.__typename)"
                            :url="mapURI(link)"
                            :blank="link.openInNewWindow"
                            class="the-navigation__link"
                        >
                            {{ link.header }}
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script setup>
import getMainNavigation from '~/graphql/queries/getMainNavigation.graphql';
import { useNuxtApp } from 'nuxt/app';

const { $i18n } = useNuxtApp();

const siteHandle = $i18n?.localeProperties?.value?.craftSiteHandle;

const { data } = await useAsyncQuery({
    query: getMainNavigation,
    variables: { siteHandle }
});

const mainNavigation = computed(() => data.value?.globalSet.mainNavigation ?? null );
const secondaryNavigation = computed(() => data.value?.globalSet.secondaryNavigation ?? null);
const privacyLinks = computed(() => data.value?.globalSet.privacyLinks ?? null);
const secondaryLinkList = computed(() => secondaryNavigation.value?.[0]?.linkMultiple ?? []);
const privacyLinkList = computed(() => privacyLinks.value?.[0]?.linkMultiple ?? []);
</script>

<script>
import Navigation from '~/mixins/navigation';
export default {
    mixins: [
        Navigation
    ],
};
</script>

<style lang="less" src="./TheFooter.less" scoped></style>
