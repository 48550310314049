import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFlexibleSectionsBlocksAccordion, LazyFlexibleSectionsBlocksAnchor, LazyFlexibleSectionsBlocksAnnouncementCard, LazyFlexibleSectionsBlocksAnnouncementCards, LazyFlexibleSectionsBlocksBlogSelect, LazyFlexibleSectionsBlocksButton, LazyFlexibleSectionsBlocksCampusBlocks, LazyFlexibleSectionsBlocksCurriculum, LazyFlexibleSectionsBlocksDownloadForm, LazyFlexibleSectionsBlocksEventBrite, LazyFlexibleSectionsBlocksFacilityList, LazyFlexibleSectionsBlocksFactsAndFigures, LazyFlexibleSectionsBlocksFormie, LazyFlexibleSectionsBlocksFullWidthImage, LazyFlexibleSectionsBlocksIconList, LazyFlexibleSectionsBlocksImage, LazyFlexibleSectionsBlocksLinkBlockHighlight, LazyFlexibleSectionsBlocksLinkBlocks, LazyFlexibleSectionsBlocksPartnerSlider, LazyFlexibleSectionsBlocksPhotoCollage, LazyFlexibleSectionsBlocksPhotoGallery, LazyFlexibleSectionsBlocksProfileCardsLarge, LazyFlexibleSectionsBlocksProfileCardsSmall, LazyFlexibleSectionsBlocksProfileHero, LazyFlexibleSectionsBlocksQuote, LazyFlexibleSectionsBlocksRichText, LazyFlexibleSectionsBlocksSocials, LazyFlexibleSectionsBlocksTextImage, LazyFlexibleSectionsBlocksTikTok, LazyFlexibleSectionsBlocksTipSlider, LazyFlexibleSectionsBlocksUSPs, LazyFlexibleSectionsBlocksVideo, LazyFormieFormFieldsAddress, LazyFormieFormFieldsAgree, LazyFormieFormFieldsCheckboxes, LazyFormieFormFieldsDropdown, LazyFormieFormFieldsEmail, LazyFormieFormFieldsFileUpload, LazyFormieFormFieldsHeading, LazyFormieFormFieldsHidden, LazyFormieFormFieldsMultiLineText, LazyFormieFormFieldsName, LazyFormieFormFieldsSingleLineText } from '#components'
const lazyGlobalComponents = [
  ["FlexibleSectionsBlocksAccordion", LazyFlexibleSectionsBlocksAccordion],
["FlexibleSectionsBlocksAnchor", LazyFlexibleSectionsBlocksAnchor],
["FlexibleSectionsBlocksAnnouncementCard", LazyFlexibleSectionsBlocksAnnouncementCard],
["FlexibleSectionsBlocksAnnouncementCards", LazyFlexibleSectionsBlocksAnnouncementCards],
["FlexibleSectionsBlocksBlogSelect", LazyFlexibleSectionsBlocksBlogSelect],
["FlexibleSectionsBlocksButton", LazyFlexibleSectionsBlocksButton],
["FlexibleSectionsBlocksCampusBlocks", LazyFlexibleSectionsBlocksCampusBlocks],
["FlexibleSectionsBlocksCurriculum", LazyFlexibleSectionsBlocksCurriculum],
["FlexibleSectionsBlocksDownloadForm", LazyFlexibleSectionsBlocksDownloadForm],
["FlexibleSectionsBlocksEventBrite", LazyFlexibleSectionsBlocksEventBrite],
["FlexibleSectionsBlocksFacilityList", LazyFlexibleSectionsBlocksFacilityList],
["FlexibleSectionsBlocksFactsAndFigures", LazyFlexibleSectionsBlocksFactsAndFigures],
["FlexibleSectionsBlocksFormie", LazyFlexibleSectionsBlocksFormie],
["FlexibleSectionsBlocksFullWidthImage", LazyFlexibleSectionsBlocksFullWidthImage],
["FlexibleSectionsBlocksIconList", LazyFlexibleSectionsBlocksIconList],
["FlexibleSectionsBlocksImage", LazyFlexibleSectionsBlocksImage],
["FlexibleSectionsBlocksLinkBlockHighlight", LazyFlexibleSectionsBlocksLinkBlockHighlight],
["FlexibleSectionsBlocksLinkBlocks", LazyFlexibleSectionsBlocksLinkBlocks],
["FlexibleSectionsBlocksPartnerSlider", LazyFlexibleSectionsBlocksPartnerSlider],
["FlexibleSectionsBlocksPhotoCollage", LazyFlexibleSectionsBlocksPhotoCollage],
["FlexibleSectionsBlocksPhotoGallery", LazyFlexibleSectionsBlocksPhotoGallery],
["FlexibleSectionsBlocksProfileCardsLarge", LazyFlexibleSectionsBlocksProfileCardsLarge],
["FlexibleSectionsBlocksProfileCardsSmall", LazyFlexibleSectionsBlocksProfileCardsSmall],
["FlexibleSectionsBlocksProfileHero", LazyFlexibleSectionsBlocksProfileHero],
["FlexibleSectionsBlocksQuote", LazyFlexibleSectionsBlocksQuote],
["FlexibleSectionsBlocksRichText", LazyFlexibleSectionsBlocksRichText],
["FlexibleSectionsBlocksSocials", LazyFlexibleSectionsBlocksSocials],
["FlexibleSectionsBlocksTextImage", LazyFlexibleSectionsBlocksTextImage],
["FlexibleSectionsBlocksTikTok", LazyFlexibleSectionsBlocksTikTok],
["FlexibleSectionsBlocksTipSlider", LazyFlexibleSectionsBlocksTipSlider],
["FlexibleSectionsBlocksUSPs", LazyFlexibleSectionsBlocksUSPs],
["FlexibleSectionsBlocksVideo", LazyFlexibleSectionsBlocksVideo],
["FormieFormFieldsAddress", LazyFormieFormFieldsAddress],
["FormieFormFieldsAgree", LazyFormieFormFieldsAgree],
["FormieFormFieldsCheckboxes", LazyFormieFormFieldsCheckboxes],
["FormieFormFieldsDropdown", LazyFormieFormFieldsDropdown],
["FormieFormFieldsEmail", LazyFormieFormFieldsEmail],
["FormieFormFieldsFileUpload", LazyFormieFormFieldsFileUpload],
["FormieFormFieldsHeading", LazyFormieFormFieldsHeading],
["FormieFormFieldsHidden", LazyFormieFormFieldsHidden],
["FormieFormFieldsMultiLineText", LazyFormieFormFieldsMultiLineText],
["FormieFormFieldsName", LazyFormieFormFieldsName],
["FormieFormFieldsSingleLineText", LazyFormieFormFieldsSingleLineText],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
