<template>
    <div>
        <small>{{ $t('navigation.social-heading') }}</small>
        <ul class="social-links">
            <li>
                <a
                    href="https://www.youtube.com/user/hotelschoolth"
                    rel="noopener referrer"
                    target="_blank"
                    :data-ga-category="gaCategory"
                    data-ga-action="click"
                    data-ga-label="youtube"
                    title="YouTube"
                ><BaseIcon icon="youtube" /><div class="sr-only">Youtube</div></a>
            </li>
            <li>
                <a
                    href="https://www.linkedin.com/school/hotelschool-the-hague"
                    rel="noopener referrer"
                    target="_blank"
                    :data-ga-category="gaCategory"
                    data-ga-action="click"
                    data-ga-label="linkedin"
                    title="LinkedIn"
                ><BaseIcon icon="linkedin" /><div class="sr-only">LinkedIn</div></a>
            </li>
            <li>
                <a
                    href="https://twitter.com/hotelschoolth"
                    rel="noopener referrer"
                    target="_blank"
                    :data-ga-category="gaCategory"
                    data-ga-action="click"
                    data-ga-label="twitter"
                    title="Twitter"
                ><BaseIcon icon="twitter" /><div class="sr-only">Twitter</div></a>
            </li>
            <li>
                <a
                    href="https://www.facebook.com/HotelschoolTH"
                    rel="noopener referrer"
                    target="_blank"
                    :data-ga-category="gaCategory"
                    data-ga-action="click"
                    data-ga-label="facebook"
                    title="Facebook"
                ><BaseIcon icon="facebook" /><div class="sr-only">Facebook</div></a>
            </li>
            <li>
                <a
                    href="https://www.instagram.com/hotelschoolthehague"
                    rel="noopener referrer"
                    target="_blank"
                    :data-ga-category="gaCategory"
                    data-ga-action="click"
                    data-ga-label="instagram"
                    title="Instagram"
                ><BaseIcon icon="instagram" /><div class="sr-only">Instagram</div></a>
            </li>
            <li>
                <a
                    href="https://www.tiktok.com/@hotelschoolthehaguenl"
                    rel="noopener referrer"
                    target="_blank"
                    :data-ga-category="gaCategory"
                    data-ga-action="click"
                    data-ga-label="tiktok"
                    title="TikTok"
                >
                    <BaseIcon icon="tiktok" /><div class="sr-only">TikTok</div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        gaCategory: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="less" src="./SocialLinks.less"></style>
